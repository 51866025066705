<template>
  <div class="member_wrap">
    <div class="member_top">
      램 일반회원가입
      <router-link to="/member/login"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <div class="member_box">
      <div class="mem_agree">
        <div class="box_1">
          램 일반회원가입을 진심으로 환영합니다.<br />가입절차로 아래 약관에
          동의가 필요합니다.
        </div>
        <div class="box_2">
          <ul>
            <li>
              <label for="totalCheck"
                ><input
                  type="checkbox"
                  name=""
                  id="totalCheck"
                  v-model="totalCheck"
                /><b>전체동의</b></label
              >
            </li>
            <li>
              <div>
                <label for="service"
                  ><input
                    type="checkbox"
                    name=""
                    id="service"
                    v-model="service"
                  />램 이용약관 동의(필수)</label
                ><router-link to="/policy/service"
                  ><img src="@/assets/images/sub/read_agree.png" alt=""
                /></router-link>
              </div>
            </li>
            <!-- <li>
              전자거래 금융약관
            </li> -->
            <li>
              <div>
                <label for="privacy"
                  ><input
                    type="checkbox"
                    name=""
                    id="privacy"
                    v-model="privacy"
                  />개인정보 수집이용 동의(필수)</label
                ><router-link to="/policy/privacy"
                  ><img src="@/assets/images/sub/read_agree.png" alt=""
                /></router-link>
              </div>
            </li>
            <li>
              <div>
                <label for="over14"
                  ><input
                    type="checkbox"
                    name=""
                    id="over14"
                    v-model="over14"
                  />만 14세 이상입니다.(필수)</label
                >
                <!-- <router-link to="#"
                  ><img src="@/assets/images/sub/read_agree.png" alt=""
                /></router-link> -->
              </div>
              <div class="over_14">
                <div>
                  리뷰앤메이크머니(주)는 [개인정보 보호법] 제22조 등 관련 법령을
                  감안해 만 14세 미만 아동의 램 일반 회원 가입을 불허합니다.
                </div>
              </div>
            </li>
            <li>
              <div>
                <label for="mailing"
                  ><input
                    type="checkbox"
                    name=""
                    id="mailing"
                    v-model="mailing"
                  />램 메일 수신 동의(선택)</label
                ><router-link to="/policy/mail"
                  ><img src="@/assets/images/sub/read_agree.png" alt=""
                /></router-link>
              </div>
            </li>
            <li>
              <div>
                <label for="sms"
                  ><input type="checkbox" name="" id="sms" v-model="sms" />램
                  SMS 수신 동의(선택)</label
                ><router-link to="/policy/sms"
                  ><img src="@/assets/images/sub/read_agree.png" alt=""
                /></router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="mem_btn">
        <router-link to="" @click.native="check_terms">다음으로</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "약관동의",
    };
  },
  metaInfo() {
    return {
      title: `${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        { vmid: "og:image", property: "og:image", content: "" },
      ],
    };
  },
  created() {
    this.$store.dispatch("member/userDataClear", {
      service: false,
      finance: false,
      privacy: false,
      over14: false,
      mailing: false,
      sms: false,
      totalCheck: false,
    });
    this.$store.dispatch("common/setFooter", {
      viewFooter: false,
    });
  },
  computed: {
    ...mapState("member", [
      "service",
      "finance",
      "privacy",
      "over14",
      "mailing",
      "sms",
    ]),
    service: {
      get() {
        return this.$store.state.member.service;
      },
      set(service) {
        this.$store.commit("member/updateUserInfo", { service });
      },
    },
    privacy: {
      get() {
        return this.$store.state.member.privacy;
      },
      set(privacy) {
        this.$store.commit("member/updateUserInfo", { privacy });
      },
    },
    over14: {
      get() {
        return this.$store.state.member.over14;
      },
      set(over14) {
        this.$store.commit("member/updateUserInfo", { over14 });
      },
    },
    mailing: {
      get() {
        return this.$store.state.member.mailing;
      },
      set(mailing) {
        this.$store.commit("member/updateUserInfo", { mailing });
      },
    },
    sms: {
      get() {
        return this.$store.state.member.sms;
      },
      set(sms) {
        this.$store.commit("member/updateUserInfo", { sms });
      },
    },
    totalCheck: {
      get() {
        return this.$store.state.member.totalCheck;
      },
      set(totalCheck) {
        this.$store.commit("member/updateUserInfo", {
          service: totalCheck,
          finance: totalCheck,
          privacy: totalCheck,
          over14: totalCheck,
          mailing: totalCheck,
          sms: totalCheck,
          totalCheck,
        });
      },
    },
  },
  methods: {
    check_terms() {
      if (!this.service) {
        this.$toast.default("램 이용약관에 동의 해주세요.");
        return false;
      }
      // if (!this.finance) {
      //   this.$toast.default("램 전자금융거래 이용 약관에 동의 해주세요.");
      //   return false;
      // }
      if (!this.privacy) {
        this.$toast.default("개인정보 수집이용 동의에 동의 해주세요.");
        return false;
      }
      if (!this.over14) {
        this.$toast.default(
          "만 14세 미만 아동의 램 일반 회원 가입을 불허합니다."
        );
        return false;
      }
      this.$store.commit("member/updateUserInfo", {
        service: true,
        finance: true,
        privacy: true,
        over14: this.over14,
        mailing: this.mailing,
        sms: this.sms,
      });
      this.$router.push("/member/auth_phone");
    },
  },
};
</script>

<style lang="scss" scoped>
.mem_btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  a {
    height: 50px !important;
    line-height: 50px !important;
  }
}
.over_14 {
  margin: 10px 20px;
  div {
    padding: 10px 10px;
    background-color: #eee;
    border-radius: 3px;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
